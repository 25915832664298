body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee !important;
  /*background-image: linear-gradient(#5e60ce 38%, #eee 38%) !important;
  height: 100vh;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-color {
  background-color: #5e60ce;
}

.logo {
  height: 100px;
}

.playstore-badge {
  width: 100%;
  max-width: 150px;
}

.form-wrapper {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
}

.menubar {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
}

@media screen and (max-width: 576px) {
  .homepage-columns {
    flex-direction: column-reverse;
  }
}